import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 78",
  week: "Semana 12",
  day: "30",
  month: "May",
  monthNumber: "05",
  date: "2020-05-30",
  path: "/cronologia/semana-12#dia-30-may/",
};

const Day78 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModDailyHighlight>
          Cuarto día del periodo de luto oficial
        </ModDailyHighlight>
        <ModText>
          En los últimos siete días se han diagnosticado 2,825 casos mediante
          pruebas PCR. En ese mismo periodo, 287 personas han requerido
          hospitalización (10,1% de los diagnósticos), de los que diez han sido
          ingresos en la UCI.
        </ModText>
        <ModText>
          En estos siete días, se han perdido 43 vidas por causa de la
          enfermedad. Esta cifra puede subir notablemente, pues solo se suman
          los casos en que consta como fecha de fallecimiento en día previo a la
          realización del informe.
        </ModText>
        <ModText>
          La entrada en vigor del nuevo protocolo de vigilancia -{" "}
          <InlineLink link="https://www.mscbs.gob.es/profesionales/saludPublica/ccayes/alertasActual/nCov-China/documentos/COVID19_Estrategia_vigilancia_y_control_e_indicadores.pdf">
            Estrategia de Diagnóstico, Vigilancia y Control en la Fase de
            Transición de la Pandemia de COVID-19 Indicadores de seguimiento
          </InlineLink>
          - está provocando que las series de datos notificadas hasta la fecha
          sufran esta revisión.
        </ModText>
        <ModImage
          src="/images/svg/04_abr_violencia_genero.svg"
          alt="violencia de género"
        />

        <ModText>
          Desde que empezó la crisis de COVID-19⁣ los servicios de atención a
          las víctimas de <strong>#ViolenciaDeGénero</strong> han recibido más
          de <strong>19.000 peticiones de ayuda</strong>.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day78;
